<template>
  <div class="columns is-multiline has-background-bluedark2">

    <div class="column is-4 has-background-bluedark2">
      <b-table 
        blueDark2 
        v-if="categoriasTable"
        :data="data" 
        :columns="columns" 
        :selected.sync="selected" 
        focusable 
        hoverable
        draggable
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
        default-sort="ordem"
        default-sort-direction="asc"
      ></b-table>
    </div>

    <div class="column is-8 has-background-bluedark">

      <div class="columns is-multiline">
        <div class="column is-full">
          <b-tabs type="is-boxed" size="is-small" :vertical="$mq !== 'sm'" :expanded="$mq == 'sm'">
            <b-tab-item label="Cadastro" icon="id-badge" icon-pack="fas">
              <div class="columns is-multiline">
                <div class="column is-full">
                  <b-button type="is-success" size="is-small" @click="save()">Salvar</b-button>
                  <b-button type="is-warning" size="is-small" @click="reset()">Limpar</b-button>
                  <b-button v-if="form.id" type="is-danger" size="is-small" @click="del()">Excluir</b-button>
                </div>
                <div class="column has-background-bluedark2">
                  <div class="columns is-multiline">
                    <div class="column is-half">
                      <b-field label="Slug">
                        <b-input v-model="form.slug"></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field label="Descrição">
                        <b-input v-model="form.descricao"></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Ligas" icon="trophy" icon-pack="fas">
              <div class="columns is-multiline">
                <div class="column is-full has-background-bluedark2">
                  <b-table 
                    blueDark2 
                    v-if="ligasTable"
                    :data="ligas" 
                    focusable 
                    hoverable
                    draggable
                    @dragstart="dragstart"
                    @drop="dropLiga"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    default-sort="ordem_liga"
                    default-sort-direction="asc"
                  >
                    <template slot-scope="props">
                      <b-table-column
                        field="ordem_liga"
                        label="Ordem"
                        sortable
                        right
                        numeric
                      >
                        {{ props.row.ordem_liga }}
                      </b-table-column>
                      <b-table-column label="Liga">
                        <div class="columns is-multiline is-gapless">
                          <div class="column is-4">
                            <div class="columns is-multiline is-gapless">
                              <div class="column is-full">
                                {{ formatIdLiga(props.row) }}
                                <!-- Liga {{ props.row.id_liga }} / Sala {{ props.row.id_sala }} -->
                              </div>
                              <div class="column is-full">
                                {{ props.row.id_influencer }} - {{ props.row.inf_nome }}
                              </div>
                              <div class="column is-full">
                                {{ props.row.titulo }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-3">
                            <div class="columns is-multiline is-gapless">
                              <div class="column is-full">
                                {{ props.row.tipo_descricao }}
                              </div>
                              <div class="column is-full">
                                {{ formatApuracao(props.row) }}
                                <!-- {{ props.row.apuracao_descricao }} -->
                              </div>
                              <div class="column is-full">
                                Rodada {{ props.row.rodada_inicio }} {{ ( props.row.rodada_inicio !== props.row.rodada_fim ) ? ` - ${props.row.rodada_fim} ` : '' }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-3">
                            <div class="columns is-multiline is-gapless">
                              <div class="column is-full">
                                <label class='subtitle'>Inscrição:</label> {{ props.row.valor }}
                              </div>
                              <div class="column is-full">
                                <label class='subtitle'>Times Insc.:</label> {{ props.row.qtd_time }}
                              </div>
                              <div class="column is-full">
                                <label class='subtitle'>Qtd.Max.Time:</label> {{ props.row.max_qtd_time }}
                              </div>
                              <div class="column is-full">
                                <label class='subtitle'>Qtd.Max.Cliente:</label> {{ props.row.max_qtd_time_pssa }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-2">
                            <div class="columns is-multiline is-gapless">
                              <div class="column is-full">
                                <span
                                  :class="{
                                    'tag is-danger': props.row.status == 'encerrada',
                                    'tag is-warning': props.row.status == 'em_andamento',
                                    'tag is-success': props.row.status == 'aberta',
                                    'tag is-info': props.row.status == 'pre_venda',
                                  }"
                                >
                                  {{ props.row.status }}
                                </span>
                              </div>
                              <div class="column is-full">
                                <div class="columns is-gapless">
                                  <div 
                                    class="column is-narrow"
                                    style="padding-right: 0.5em !important;"
                                    v-if="props.row.flag_destaque"
                                  >
                                    <b-icon
                                      type="is-success"
                                      pack="fas"
                                      size="is-small"
                                      icon="check"
                                    />
                                  </div>
                                  <div 
                                    class="column is-narrow"
                                    style="padding-right: 0.5em !important;"
                                    v-if="!props.row.flag_destaque"
                                  >
                                    <b-icon
                                      type="is-danger"
                                      pack="fas"
                                      size="is-small"
                                      icon="times"
                                    />
                                  </div>
                                  <div class="column">
                                    <label class='subtitle'>Destaque</label>
                                  </div>
                                </div>
                              </div>
                              <div class="column is-full">
                                <div class="columns is-gapless">
                                  <div 
                                    class="column is-narrow"
                                    style="padding-right: 0.5em !important;"
                                    v-if="props.row.flag_capitao"
                                  >
                                    <b-icon
                                      type="is-success"
                                      pack="fas"
                                      size="is-small"
                                      icon="check"
                                    />
                                  </div>
                                  <div 
                                    class="column is-narrow"
                                    style="padding-right: 0.5em !important;"
                                    v-if="!props.row.flag_capitao"
                                  >
                                    <b-icon
                                      type="is-danger"
                                      pack="fas"
                                      size="is-small"
                                      icon="times"
                                    />
                                  </div>
                                  <div class="column">
                                    <label class='subtitle'>Capitão</label>
                                  </div>
                                </div>
                              </div>
                              <div class="column is-full">
                                <div class="columns is-gapless">
                                  <div 
                                    class="column is-narrow"
                                    style="padding-right: 0.5em !important;"
                                    v-if="props.row.flag_clonagem"
                                  >
                                    <b-icon
                                      type="is-success"
                                      pack="fas"
                                      size="is-small"
                                      icon="check"
                                    />
                                  </div>
                                  <div 
                                    class="column is-narrow"
                                    style="padding-right: 0.5em !important;"
                                    v-if="!props.row.flag_clonagem"
                                  >
                                    <b-icon
                                      type="is-danger"
                                      pack="fas"
                                      size="is-small"
                                      icon="times"
                                    />
                                  </div>
                                  <div class="column">
                                    <label class='subtitle'>Clonagem</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-table-column>
                    </template>                  
                  </b-table>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Moment from 'moment'

export default {
  name: 'Categorias',
  data() {
    return {
      categoriasTable: false,
      ligasTable: false,
      isLoading: false,
      form: {
        "slug": "",
        "descricao": "",
      },
      data: [],
      ligas: [],
      games: [],
      selected: {},
      columns: [
        {
          field: 'ordem',
          label: 'Ordem',
          numeric: true,
          sortable: true
        },
        {
          field: 'id',
          label: 'ID',
          numeric: true
        },
        {
          field: 'slug',
          label: 'Slug'
        },
        {
          field: 'descricao',
          label: 'Descrição'
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['userId','userInfo']),
  },
  watch: {
    selected: async function (val) {
      if (this.selected.id){
        this.form = this.selected
        this.ligas = (await this.getCategoriasLigas(this.selected.id)).data
        // this.games = (await this.getCategoriasGames(this.selected.id)).data
      }
    }
  },
  async created() {
    let loader = this.$loading.show()
    this.data = (await this.getCategorias()).data
    this.categoriasTable = true;
    this.ligasTable = true;
    loader.hide()
  },
  methods: {
    ...mapActions(["createCategoria", "deleteCategoria", "getCategorias", "sortCategoria", "sortCategoriaLiga", "getCategoriasLigas", "getCategoriasGames"]),
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = "copy";
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-selected");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      payload.event.preventDefault();
    },
    async drop(payload) {
      let loader = this.$loading.show()
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      let index = this.data.findIndex((i) => i.id == this.draggingRow.id);
      if (index !== -1) {
        this.categoriasTable = false;
        this.isLoading = true;
        let oldOrder = this.draggingRowIndex + 1;
        let newOrder = droppedOnRowIndex + 1;
        this.data.map((l) => {
          if (newOrder > oldOrder) {
            l.ordem =
              l.ordem >= oldOrder && l.ordem <= newOrder
                ? l.ordem - 1
                : l.ordem;
          } else if (newOrder < oldOrder) {
            l.ordem =
              l.ordem >= newOrder && l.ordem <= oldOrder
                ? l.ordem + 1
                : l.ordem;
          }
        });
        this.data[index].ordem = newOrder;
        await this.sortCategoria({
          userId: this.userId,
          categorias: this.data.map((l) => {
            return { id: l.id, order: l.ordem };
          }),
        });
        this.$toasted.success(
          `Alterada a ordem da Categoria ${this.draggingRow.id} de ${oldOrder} para ${newOrder}`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        this.isLoading = false;
        this.categoriasTable = true;
      } else {
        this.$toasted.error(
          `Alteração da ordenação não realizada pois a ordem da Categoria ${this.draggingRow.id} não foi localizada!`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      }
      loader.hide()
    },
    async dropLiga(payload) {
      let loader = this.$loading.show()
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      let index = this.ligas.findIndex((i) => i.id_sala == this.draggingRow.id_sala);
      if (index !== -1) {
        this.ligasTable = false;
        this.isLoading = true;
        let oldOrder = this.draggingRowIndex + 1;
        let newOrder = droppedOnRowIndex + 1;
        this.ligas.map((l) => {
          if (newOrder > oldOrder) {
            l.ordem_liga =
              l.ordem_liga >= oldOrder && l.ordem_liga <= newOrder
                ? l.ordem_liga - 1
                : l.ordem_liga;
          } else if (newOrder < oldOrder) {
            l.ordem_liga =
              l.ordem_liga >= newOrder && l.ordem_liga <= oldOrder
                ? l.ordem_liga + 1
                : l.ordem_liga;
          }
        });
        this.ligas[index].ordem_liga = newOrder;
        await this.sortCategoriaLiga({
          userId: this.userId,
          categoriaId: this.selected.id,
          ligas: this.ligas.map((l) => {
            return { id_sala: l.id_sala, order: l.ordem_liga };
          }),
        });
        this.$toasted.success(
          `Alterada a ordem da Liga ${this.draggingRow.id_sala} de ${oldOrder} para ${newOrder}`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        this.isLoading = false;
        this.ligasTable = true;
      } else {
        this.$toasted.error(
          `Alteração da ordenação não realizada pois a ordem da Liga ${this.draggingRow.id_sala} não foi localizada!`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      }
      loader.hide()
    },
    formatter (form) {
      
      return {
        id: (form.id) ? form.id : 0,
        slug: form.slug,
        descricao: form.descricao,
        ordem: (form.ordem) ? form.ordem : 0
       }
    },
    async save() {

      let loader = this.$loading.show()

      try {
        let params = this.formatter(JSON.parse(JSON.stringify(this.form)))
        let data = await this.createCategoria(params)

        if (data.data.status == 'success'){
          this.$toasted.success('Categoria registrada com sucesso!', {
            duration: 3000,
            position: 'top-center'
          })
          this.data = (await this.getCategorias()).data
          this.reset()
        } else {
          this.$toasted.error('Ocorreu um problema na atualização! Favor informar ao administrador', {
            duration: 3000,
            position: 'top-center'
          })
        }

      } catch (err) {
        console.log(err)
        this.$toasted.error('Ocorreu um problema ao atualizar a Categoria!', {
          duration: 3000,
          position: 'top-center'
        })
      }

      loader.hide()
    },
    formatIdLiga(sala){
      return sala.modalidade == 'liga'
      ? `Liga: ${sala.id_liga} / sala: ${sala.id_sala}`
      : `Minigame: ${sala.id_game} / sala: ${sala.id_sala}`
    },
    formatApuracao(sala){
      return sala.modalidade == 'liga'
      ? `${sala.apuracao_descricao}`
      : `${sala.apuracao_descricao_scouts} / ${sala.apuracao_descricao_scouts_desempate}`
    },
    async reset(){
      this.form = {
        "slug": "",
        "descricao": ""
      }
      this.selected = {}
    },
    async del(){
      if (this.form.id){
        try {

          let data = await this.deleteCategoria(this.form.id)

          if (data.data){
            this.$toasted.success('Categoria excluída com sucesso!', {
              duration: 3000,
              position: 'top-center'
            })

            this.data = (await this.getCategorias()).data
            this.reset()
          } else {
            this.$toasted.error('Exclusão não permitida devido a registros relacionados!', {
              duration: 3000,
              position: 'top-center'
            })
          }

        } catch (err) {
          console.log(err)
          this.$toasted.error('Ocorreu um problema ao excluir a Categoria!', {
            duration: 3000,
            position: 'top-center'
          })
        }
      }
    },
  }
}
</script>

<style scoped>
.sales-table {
  width: 100%;
  height: auto !important;
  overflow-y: auto;
  background-color: #4d4d4d;
  border-radius: 7px;
  padding: 5px;
}
</style>
